var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen flex w-full bg-img"},[_c('div',{staticClass:"vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"},[_c('vx-card',[_c('div',{staticClass:"full-page-bg-color",attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"},[_c('img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/images/pages/reset-password.png"),"alt":"login"}})]),_c('div',{staticClass:"vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg"},[_c('div',{staticClass:"p-8"},[_c('div',{staticClass:"vx-card__title"},[_c('h4',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t('password.reset.page.title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('password.reset.page.message')))])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updatePassword)}}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('administration.userform.form.input.password.new'),"rules":"password:@confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('administration.userform.form.input.password.new'),"name":"password","type":"password"},model:{value:(_vm.userLocal.plainPassword),callback:function ($$v) {_vm.$set(_vm.userLocal, "plainPassword", $$v)},expression:"userLocal.plainPassword"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('administration.userform.form.input.password.confirm'),"vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('administration.userform.form.input.password.confirm'),"name":"confirmPassword","type":"password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"mt-8"},[_c('vs-button',{staticClass:"w-full mb-2",attrs:{"button":"submit","color":"warning","disabled":invalid},on:{"click":_vm.updatePassword}},[_vm._v(_vm._s(_vm.$t('password.reset.button.update_password')))])],1)],1)])])]}}])})],1)])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }